
import setUrl from "~/mixins/setUrl.js";
export default {
  layout: "community",
  data: () => ({
    mixins: [setUrl],
    loading: false,
    data: [],
    page: 1,
    limit: 10,
    search: "",
    keyword: "",
    noResult: false,
    searchResult: "",
  }),
  created() {
    this.search = this.$route.query.keyword ? this.$route.query.keyword : "";
    this.searchResult = this.$route.query.keyword
      ? this.$route.query.keyword
      : "";
    this.$store.dispatch("public/setNavbarTitle", "New Community");
  },
  mounted() {
    this.search = this.$route.query.keyword ? this.$route.query.keyword : "";
    this.searchResult = this.$route.query.keyword
      ? this.$route.query.keyword
      : "";
  },
  async fetch() {
    await this.fetchCommunity();
  },
  methods: {
    onSubmit() {
      this.data = [];
      this.$router.push({ query: { keyword: this.search } });
      this.page = 1;
      this.searchResult = this.search;
      this.fetchCommunity();
    },
    loadMore() {
      this.page += 1;
      this.fetchCommunity();
    },
    async fetchCommunity() {
      this.loading = true;
      this.keyword = this.search;
      try {
        const res = await this.$getCommunities(this.limit, this.page);
        this.noResult = res && res.length === 0;
        this.data.push(...res);
      } catch (error) {
        throw new Error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
