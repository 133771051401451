
export default {
  layout: "detail",
  /**
   * Get Community Data from API
   * return communityData
   * */
  async asyncData({ params, store, redirect }) {
    const payload = {
      param_id: params.id,
      param_slug: "",
    };

    try {
      const result = await store.dispatch("public/getCommunityDetail", payload);
      store.commit("community/SET_COMMUNITY_PROFILE", result);
      return {
        communityData: result,
        serverID: params.id,
      };
    } catch (error) {
      if (error.data.message.includes("Community not found")) {
        redirect("/community/not-found");
      } else {
        throw error;
      }
    }
  },
  /**
   * * Data
   */
  data: () => ({
    modalFab: false,
    activeTabIndex: 0,
    isPageLoading: false,
    communityID: null,
    pageSlug: "",
    links: [],
    communityUserDetailData: {
      is_member: false,
    },
    sliderMenuData: [
      {
        name: "All Post",
      },
      {
        name: "Member",
      },
      {
        name: "Media",
      },
      {
        name: "Listing",
      },
    ],
    carouselSettings: {
      arrows: false,
      dots: false,
      infinite: false,
      variableWidth: true,
      slidesToScroll: 2,
    },
  }),
  watch: {
    "this.$store.state.auth.user.isLogin"(val, val2) {
      console.info(val, val2);
    },
  },
  /**
   * * Methods
   */
  methods: {
    handleFabClick(item) {
      switch (item) {
        case "listing":
          window.open(
            process.env.BASE_LISTING +
              `community/${this.$store.state.community.profile.id}/listing`,
            "_self"
          );
          break;
        case "post":
          this.$router.push(
            `/community/${this.$store.state.community.profile.id}/post`
          );
          break;
          break;
        default:
          break;
      }
    },
    closeFab() {
      this.modalFab = false;
    },
    clickFab() {
      this.modalFab = this.$refs["vue-tab"].active;
    },
    /**
     * TODO: Handler
     */
    handleContenChange(selectedContent) {
      this.activeTabIndex = selectedContent;
    },
    /**
     * TODO: API Client
     */
    async fetchDetail() {
      this.isPageLoading = true;
      const data = {
        param_id: this.communityID,
        param_slug: this.pageSlug,
      };
      await this.$store
        .dispatch("public/getCommunityDetail", data)
        .then((res) => {
          this.links = res.link; // force majeur cause value key is deleted by somehow
          this.$store.commit("auth/SET_MEMBER", res.member_data);
          this.$store.commit("community/SET_COMMUNITY_PROFILE", res);
          this.communityUserDetailData = res;
          this.isPageLoading = false;
          //  define Ads Slot
        })
        .catch((err) => {
          this.isPageLoading = false;
          throw new Error(err);
        });
    },
    joinCommunity() {
      this.$submitJoinCommunity(this.communityID)
        .then((res) => {
          this.fetchDetail();
        })
        .catch((err) => {
          throw err;
        });
    },
    /**
     * TODO: Utitlity
     */
    checkURLParams() {
      if (this.$route.params.id || this.serverID) {
        this.pageSlug = this.$route.query.slug;
        this.communityID = this.$route.params.id;
        this.fetchDetail();
      }
    },
  },
  /**
   * * Mounted
   */
  mounted() {
    this.sliderMenuData[1].name = this.communityData.memberlist_name;
    this.checkURLParams();
  },
  // Page Head tag
  head() {
    return {
      title:
        this.communityData && this.communityData.community_name
          ? `${this.communityData.community_name} Community` + " | Modoc"
          : "Modoc",
      meta: [
        {
          hid: "og:title",
          property: "og:title",
          content:
            this.communityData && this.communityData.community_name
              ? "Community" + " | Modoc"
              : "Modoc",
        },
        { hid: "og:type", property: "og:type", content: "article" },
        { hid: "og:site_name", property: "og:site_name", content: "Modoc" },
        {
          hid: "description",
          name: "description",
          content:
            this.communityData && this.communityData.description
              ? this.communityData.description
              : "",
        },
        {
          hid: "og:description",
          name: "og:description",
          content:
            this.communityData && this.communityData.description
              ? this.communityData.description
              : "",
        },
        {
          hid: "og:url",
          name: "og:url",
          content: process.env.BASE_URL + this.$route.path.substring(1),
        },
        {
          property: "og:image",
          content:
            this.communityData && this.communityData.img_background
              ? this.communityData.img_background
              : "https://static.modocapis.com/kotak/images/default-question.jpg",
        },
      ],
      __dangerouslyDisableSanitizers: ["script"],
      script: [
        {
          async: true,
          src: "https://securepubads.g.doubleclick.net/tag/js/gpt.js",
        },
      ],
    };
  },
};
