
import setUrl from "~/mixins/setUrl.js";
export default {
  layout: "community",
  ssr: false,
  fetchOnServer: false,
  data: () => ({
    mixins: [setUrl],
    loading: false,
    data: [],
    page: 1,
    limit: 10,
    search: "",
    keyword: "",
    noResult: false,
    lastResult: false,
    searchResult: "",
    total: 0,
  }),
  created() {
    this.search = this.$route.query.keyword ? this.$route.query.keyword : "";
    this.searchResult = this.$route.query.keyword
      ? this.$route.query.keyword
      : "";
    this.$store.dispatch("public/setNavbarTitle", "Your Communities");
  },
  mounted() {
    this.search = this.$route.query.keyword ? this.$route.query.keyword : "";
    this.searchResult = this.$route.query.keyword
      ? this.$route.query.keyword
      : "";
  },
  async fetch() {
    await this.fetchCommunity();
  },
  methods: {
    onSubmit() {
      this.data = [];
      this.$router.push({ query: { keyword: this.search } });
      this.page = 1;
      this.searchResult = this.search;
      this.fetchCommunity();
    },
    loadMore() {
      this.page += 1;
      this.fetchCommunity();
    },
    async fetchCommunity() {
      this.loading = true;
      this.keyword = this.search;
      try {
        const res = await this.$getOwnedCommunities(this.limit, this.page);
        this.total += res.body.length;
        this.lastResult = this.total === res.total_records;
        this.noResult = res.body && res.body.length === 0;
        this.data.push(...res.body);
      } catch (err) {
        throw new Error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
